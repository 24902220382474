<template>
  <div>
    <v-app-bar app
               flat
               v-if="$vuetify.breakpoint.mdAndUp"
               class="hidden-sm-and-down"
               color="white">
      <div class="col-3 pa-0 pr-3">
        <v-select :items="translatedViewProfiles"
                  @change="changedProfile()"
                  outlined
                  class="rounded-lg"
                  hide-details
                  dense
                  item-text="label"
                  item-value="value"
                  v-model="selectedProfile"
                  :color="role === 'patient' ? 'primary' : 'secondary'"
        >
        </v-select>
      </div>
      <div class="pa-0" :class="altLang.length > 1 ? 'col-7' : 'col-9'">
        <v-text-field
          v-model="searchText"
          :placeholder="searchLabel"
          full-width
          clearable
          outlined
          hide-details
          dense
          prepend-inner-icon="fa fa-search"
          class="flex-grow-1 rounded-lg"
          :color="role === 'patient' ? 'primary' : 'secondary'"
        >
        </v-text-field>
      </div>
      <div class="col-2 pa-0 pl-3" v-if="altLang.length > 1">
        <v-select
          tile
          v-model="currentLang"
          dense
          @change="changeLanguage"
          hide-details
          outlined
          class="rounded-lg"
          :items="altLang"
          :color="role === 'patient' ? 'primary' : 'secondary'"
        >
          <template v-slot:item="{ on, item }">
            <v-list-item v-on="on" :disabled="!readyLanguages.includes(item)">
              <v-list-item-title v-text="item"></v-list-item-title>
              <v-list-item-icon>
                <v-icon v-if="readyLanguages.includes(item)" small>fa fa-check</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </template>
        </v-select>
      </div>
    </v-app-bar>
    <div
      v-else
      class="hidden-md-and-up mt-2 d-flex flex-column col-12"
      color="white">
      <v-select :items="translatedViewProfiles"
                @change="changedProfile()"
                outlined
                hide-details
                dense
                class="rounded-lg pa-0 pr-3 w-100 mb-1"
                item-text="label"
                item-value="value"
                v-model="selectedProfile"
                :color="role === 'patient' ? 'primary' : 'secondary'"
      >
      </v-select>
      <v-select
        tile
        v-if="altLang.length > 1"
        v-model="currentLang"
        dense
        @change="changeLanguage"
        hide-details
        outlined
        class="rounded-lg w-100 mb-1"
        :items="altLang"
        :color="role === 'patient' ? 'primary' : 'secondary'"
      >
        <template v-slot:item="{ on, item }">
          <v-list-item v-on="on" :disabled="!readyLanguages.includes(item)">
            <v-list-item-title v-text="item"></v-list-item-title>
            <v-list-item-icon>
              <v-icon v-if="readyLanguages.includes(item)" small>fa fa-check</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </template>
      </v-select>
      <div class="pa-0 col-12">
        <v-text-field
          v-model="searchText"
          :placeholder="searchLabel"
          full-width
          clearable
          outlined
          hide-details
          dense
          prepend-inner-icon="fa fa-search"
          class="flex-grow-1 rounded-lg"
          :color="role === 'patient' ? 'primary' : 'secondary'"
        >
        </v-text-field>
      </div>
    </div>
    <v-container fluid>
      <v-dialog
        v-model='authorModal'
        :overlay-color="role === 'patient' ? 'primary' : 'secondary'"
        max-width="900"
        scrollable
      >
        <v-card class="rounded-lg">

        <v-card-title class="headline grey lighten-2">
          {{selectedAuthor.lastName}} {{selectedAuthor.firstName}}
        </v-card-title>

        <v-card-text>
          <v-list>
            <v-list-item v-if="selectedAuthor.address && selectedAuthor.address.trim().length > 0">
              <v-list-item-avatar>
                <i class="fa fa-home"></i>
              </v-list-item-avatar>
              <v-list-item-content>
                {{selectedAuthor.address}}
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="t in selectedAuthor.telecomEntries" :key="t">
              <v-list-item-avatar>
                <i class="fa fa-address-card"></i>
              </v-list-item-avatar>
              <v-list-item-content>
                t
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
          <v-card-actions>
            <v-btn
              class="ml-auto rounded-lg"
              :color="role === 'patient' ? 'primary' : 'secondary'"
              text
              @click="authorModal = false"
            >
              {{ $t('label.close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="infoModal"
        :overlay-color="role === 'patient' ? 'primary' : 'secondary'"
        max-width="900"
        scrollable
      >
        <v-card v-if="infoModalContent" class="rounded-lg">
          <v-card-title class="headline grey lighten-2">
            {{ $t('view.related') }}: {{ infoModalContent.Label }}
          </v-card-title>

          <v-card-text>
            <v-list>
              <v-list-item v-for="(content, ndx) in infoModalContent.content"
                           :key="'content-' + ndx + '-url-' + content.url">
                <a :class="role === 'patient' ? 'primary--text' : 'secondary--text'"
                   @click="openExternal(content.url, ndx)">{{ content.publisher }} - {{ content.title }}</a> <i
                class="ml-auto" v-if="opened.includes(ndx)">({{ $t('view.opened') }})</i>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              class="ml-auto rounded-lg"
              :color="role === 'patient' ? 'primary' : 'secondary'"
              text
              @click="infoModal = false"
            >
              {{ $t('label.close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div v-if="!state || !hasConversion">
        <v-container style="height: 400px;">
          <v-row
            class="fill-height"
            align-content="center"
            justify="center"
          >
            <v-col
              class="subtitle-1 text-center"
              cols="12"
            >
              {{ $t('view.preparing') }}
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                :color="role === 'patient' ? 'primary' : 'secondary'"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div v-if="state && hasConversion">
        <div class="d-flex flex-column justify-center align-start">
          <v-chip
            :ripple="false"
            v-if="actualLang"
            class="rounded-lg d-flex justify-start align-center mb-3 px-0"
            style="width: 100%;"
            :color="role === 'patient' ? 'primary' : 'secondary'"
          >
            <div class="col-12 font-weight-bold">{{ translationWarning }}</div>
          </v-chip>
          <v-chip
            :ripple="false"
            v-if="!allTranslationsReady"
            class="rounded-lg d-flex justify-start align-center mb-3 px-0"
            style="width: 100%;"
            :color="role === 'patient' ? 'primary' : 'secondary'"
          >
            <div class="col-12 font-weight-bold">{{ translationOngoing }}</div>
          </v-chip>

          <template v-for="(group, key, groupIndex) in groupedEntries">
            <template v-if="groupIndex < showItems">
              <v-chip
                :ripple="false"
                :key="'group-' + groupIndex"
                class="rounded-lg d-flex justify-start align-center mb-3 px-0"
                style="width: 100%;"
                :color="role === 'patient' ? 'primary' : 'secondary'"
              >
                <div class="col-12 font-weight-bold">{{ key !== 'null' ? $moment(key).format('DD/MM/YYYY') : '' }}</div>
              </v-chip>
              <v-card
                v-for="(item, itemIndex) in group"
                :key="'group-' + groupIndex + '-item-' + itemIndex"
                class="rounded-lg d-flex justify-start align-stretch mb-3 px-0 flex-wrap"
                width="100%"
                min-height="64"
                outlined
                elevation="4"
              >
                <div
                  class=" col-md-2 col-12"
                >
                  <v-chip
                    :ripple="false"
                    color="grey lighten-2"
                    style="width: 100%; height: 100%;"
                    class="rounded-lg justify-center"
                  >
                    {{ translatedResourceLabel(`view.${item.entryType}`) }}
                  </v-chip>
                </div>
                <div
                  class="col-12 col-md-8 d-flex align-center"
                >
                  <span class="keepws" v-if="actualLang && item.entryType !== 'Identification'">{{ item.translation[actualLang] }}  <span
                    class="ml-1 grey--text"
                    v-if="item.status === 'inactive' && item.entryType==='Evaluation'">({{
                      $t('label.inactive')
                    }})</span></span>
                  <div class="mr-1">
                    <a @click="showPdf(item.doc.data)" :class="role === 'patient' ? 'primary--text' : 'secondary--text'"
                       text dense v-if="item.doc && item.doc.contentType === 'application/pdf'">
                      <i class="fa fa-file-pdf mr-3"></i>{{openDocumentLabel}}</a>
                    <div v-if="item.doc && item.doc.contentType === 'text/html'" v-html="item.doc.data"></div>
                  </div>
                  <span class="keepws" v-if="!actualLang && item.entryType !== 'Identification'">{{ item.label }}</span>
                  <div v-if="item.entryType === 'Identification' && state.persInfo">
                    <p>{{item.label}}
                    <span v-if="state.persInfo.birthDate" class="ml-1">° {{state.persInfo.birthDate}}</span>
                    <span v-if="state.persInfo.gender && state.persInfo.gender === 'male'" class="ml-1"><i class="fa fa-mars"></i></span>
                    <span v-if="state.persInfo.gender && state.persInfo.gender === 'female'" class="ml-1"><i class="fa fa-venus"></i></span>
                    </p>
                    <p v-if="state.persInfo.address">
                      {{state.persInfo.adress}}
                    </p>
                    <p v-if="state.persInfo.telecom">
                      {{state.persInfo.telecom}}
                    </p>
                  </div>
                  <span class="ml-1 grey--text"
                        v-if="item.status === 'inactive' && item.entryType==='Evaluation'">
                    ({{$t('label.inactive')}})</span>
                </div>
                <div
                  class="col-md-2 col-12 d-flex justify-end align-center"
                >
                  <v-btn
                    @click="showInfoModal(item)"
                    :color="role === 'patient' ? 'primary' : 'secondary'"
                    class="rounded-lg"
                    text
                    v-if="item.content && item.content.length"
                  >
                    {{ viewInfoLabel }}
                  </v-btn>
                  <v-btn
                    v-if="item.authorData"
                    @click="showAuthorModel(item)"
                    :color="role === 'patient' ? 'primary' : 'secondary'"
                    icon
                  >
                    <i class="fa fa-user-doctor"></i>
                  </v-btn>
                </div>
              </v-card>
              <div
                :key="'intersect-' + groupIndex"
                v-if="groupIndex === (showItems - 1)"
                v-intersect="onIntersect"
              >
                <span>{{ $t('button.showMore') }}</span>
              </div>
            </template>
          </template>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import i18n from "@/services/i18n";
import axios from 'axios';
import labels from '@/assets/lang/labels.json'

export default {
  i18n: i18n,
  name: "psr-view",
  props: ['state', 'role', 'drawer'],
  data() {
    return {
      selectedAuthor: {},
      authorModal: false,
      altLang: [],
      progress: 0,
      readyLanguages: [],
      translations: [],
      viewProfiles: [],
      currentLang: null,
      hasInfo: false,
      actualLang: null,
      opened: [],
      hasConversion: false,
      infoModal: false,
      selectedProfile: 'all',
      infoModalContent: null,
      searchText: '',
      tab: null,
      viewEntries: [],
      // filteredEntries: [],
      filteredProfileEntries: [],
      filtered: false,
      showItems: 10
    }
  },
  methods: {
    showAuthorModel(item) {
      this.selectedAuthor = item.authorData;
      if (this.selectedAuthor.telecom) {
        this.selectedAuthor.telecomEntries = this.selectedAuthor.telecom.split(',')
      } else {
        this.selectedAuthor.telecomEntries = [];
      }
      this.authorModal = true;
    },
    translatedResourceLabel(label) {

      if (!this.actualLang) {
        return this.$t(label)
      }
      var lang = this.actualLang.toLowerCase().split("-")[0];

      var actualLabel = label.split(".").pop();
      var base = labels.resources.filter(x => x.Base == actualLabel);
      if (base.length === 0) {
        return this.$t(label)
      }
      var val = base[0][lang.toLowerCase()];
      if (!val) {
        return this.$t(label)
      }
      return val;
    },

    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        console.log('SHOW MORE...')
        this.showItems += 10
      }
    },
    groupBy(arr, criteria) {
      const newObj = arr.reduce(function (acc, currentValue) {
        if (!acc[currentValue[criteria]]) {
          acc[currentValue[criteria]] = [];
        }
        acc[currentValue[criteria]].push(currentValue);
        return acc;
      }, {});
      return newObj;
    },

    changedProfile() {
      this.$vuetify.goTo(0)
      this.showItems = 10
      this.filteredProfileEntries = this.viewEntries.filter(x => {
        if (this.selectedProfile === 'all') {
          return true;
        }
        if (this.selectedProfile === 'summary') {
          if (!x.onset) {
            return true;
          } else {
            return ['Allergy', 'Condition', 'Family', 'Risk', 'Immunization', 'Evaluation'].includes(x.entryType);
          }
        }
        if (this.selectedProfile === 'medication') {
          return ['Medication'].includes(x.entryType);
        }
        if (this.selectedProfile === 'info') {
          return x.content && x.content.length;
        }
        return true
      });
    },
    lock() {
      this.$root.$emit('lock')
    },
    changeLanguage() {
      if (this.currentLang === this.altLang[0]) {
        this.actualLang = null;
      } else {
        this.actualLang = this.currentLang;
      }
    },
    wasOpened(ndx) {
      return this.opened.includes(ndx);
    },
    showPdf(pdf) {
      this.$root.$emit('show-pdf', pdf)
    },
    showInfoModal(content) {
      this.infoModalContent = content;
      this.opened = [];
      this.infoModal = true;
    },
    openExternal(url, ndx) {
      window.open(url, "_blank");
      this.opened.push(ndx);
      this.opened = [...this.opened];
    }
  },
  async mounted() {
    const that = this;
    this.$root.$on('reset-conversion', () => {
      that.hasConversion = false
    });
    this.altLang = [this.$t('lang.no-translation')]

    const config = {
      headers: {
        "X-TOKEN": this.state.token
      }
    }
    var summaryDto = await axios.get("/virtual-stick/summary", config);
    that.hasConversion = true;
    this.viewEntries = Object.freeze(summaryDto.data.summary.summary);
    this.searchText = '';
    var hasInfo = this.viewEntries.some(x => x.content && x.content.length);
    this.viewProfiles = hasInfo ? ['all', 'summary', 'info', 'medication'] : ['all', 'summary', 'medication'];

    this.filteredProfileEntries = [...this.viewEntries]
    // this.filteredEntries = [... this.filteredProfileEntries];
    const tmp = this.viewEntries.filter(x => x.entryType === 'Identification');
    if (tmp.length) {
      var name = tmp[0].Label
    }
    if (tmp[0].data){
      const tmpData = JSON.parse(tmp[0].data)
      console.log('data', JSON.stringify(tmpData))
      this.state.persInfo = {
        name: name,
        gender: tmpData.Gender?.toLowerCase(),
        birthDate:tmpData.BirthDate?.toLowerCase(),
        address: `${tmpData.Street} ${tmpData.Street ? ',':''} ${tmpData.Zip} ${tmpData.City}`,
        telecom: tmpData.Telecom
      }
      this.$root.$emit('update-state', {state: this.state, reply: false});
    }
    const langs = summaryDto.data.languages;
    if (langs != null && that.altLang != null && langs.length + 1 > that.altLang.length) {
      that.altLang = [this.$t('lang.no-translation'), ...langs];
      that.currentLang = that.altLang[0];
    }
    const readyLangs = summaryDto.data.readyLanguages;
    this.readyLanguages = [this.$t('lang.no-translation'), ...readyLangs];

    // window.ipcRenderer.on('reply-languages', (event, langs) => {

    // })
    // window.ipcRenderer.on('reply-language', ( ) => {
    //   that.actualLang = this.currentLang;
    // });
    // }
  },
  watch: {
    searchText() {
      this.$vuetify.goTo(0)
      this.showItems = 10
    }
  },

  computed: {
    allTranslationsReady() {
      if (this.altLang.length <= 1) {
        return true;
      }
      return this.readyLanguages.length === this.altLang.length;
    },
    openDocumentLabel() {
      var lang =  this.actualLang ? this.actualLang.toLowerCase().split("-")[0] : 'Base';
      return labels.other.openDocument[lang];
    },
    viewInfoLabel() {
      if (!this.actualLang) {
        return this.$t('view.info')
      }
      const lang = this.actualLang.toLowerCase().split("-")[0]
      return labels.other.info[lang];
    },
    translationWarning() {
      if (!this.actualLang) {
        return this.$t('view.translationWarning')
      }
      var lang =  this.actualLang.toLowerCase().split("-")[0];
      return labels.other.translationWarning[lang];
    },
    translationOngoing() {
      if (!this.actualLang) {
        return this.$t('view.translationOngoing')
      }
      var lang = this.actualLang.toLowerCase().split("-")[0];
      return labels.other.translationOngoing[lang];
    },
    searchLabel() {
      if (!this.actualLang) {
        return this.$t('placeholder.search')
      }
      const lang = this.actualLang.toLowerCase().split("-")[0]
      return labels.other.search[lang];
    },
    translatedViewProfiles() {

      if (!this.actualLang) {
        return this.viewProfiles.map(x => {
          return {
            value: x,
            label: this.$t('view.' + x)
          }
        });
      }
      var lang = this.actualLang.toLowerCase().split("-")[0];
      if (lang == "tk") {
        lang = "tr";
      }
      if (lang == 'nv') {
        lang = 'nb';
      }
      console.log('matching profiles', lang)
      return this.viewProfiles.map(x => {
        console.log('labels.profiles', x, lang,  labels.profiles[x])
        return {
          value: x,
          label: labels.profiles[x][lang]
        }
      });
    },
    filteredEntries() {
      if (this.searchText && this.searchText.length > 2) {
        const actualText = this.searchText.toLowerCase();
        return this.filteredProfileEntries.filter((x) => {
          if (x.label != null && x.label.toLowerCase().includes(actualText)) {
            return true
          }
          return !!(x.doc && x.doc.contentType === 'text/html' && x.doc.data.toLowerCase().includes(actualText));

        });
      } else {
        return this.filteredProfileEntries
      }
    },

    groupedEntries() {
      let grouped = this.groupBy(this.filteredEntries, "onset")
      let sorted = Object.keys(grouped).sort().reverse().reduce((res, key) => (res[key] = grouped[key], res), {})
      return sorted
    }
  }
}
</script>

<style scoped>

.keepws {
  white-space: pre-line;
}

table.v-table tbody td:first-child, table.v-table tbody td:not(:first-child), table.v-table tbody th:first-child, table.v-table tbody th:not(:first-child), table.v-table thead td:first-child, table.v-table thead td:not(:first-child), table.v-table thead th:first-child, table.v-table thead th:not(:first-child) {
  padding: 0 10px
}

.i-circle {
  background: #ff0000;
  color: #fff;
  padding: 5px 20px;
  border-radius: 50%;
  font-size: 35px;
}

.v-chip::before {
  display: none;
}


</style>
